import { Popup } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import logo from 'assets/images/png/logo.png';
import appSwitcher from 'assets/images/svg/appSwitcher.svg';
import { ROUTE_PATHS } from 'settings';

import { AppSwitcherContent } from './AppSwitcherContent';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  display: flex;

  & img {
    margin-right: 0.5rem;
  }

  &:hover {
    text-decoration: none;
  }
`;

const LogoAppName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.N500};
  display: flex;
  align-items: center;
`;

const AppSwitcherButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-right: 12px;
  padding: 5px;

  &:hover {
    padding: 5px;
    background-color: rgb(223, 225, 230);
    border-radius: 3px;
    cursor: pointer;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

function TopNavigationLogo(): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom-start"
        content={() => <AppSwitcherContent />}
        trigger={(triggerProps) => (
          <AppSwitcherButton {...triggerProps} type="button" onClick={() => setIsOpen(!isOpen)}>
            <img src={appSwitcher} alt="App switcher" />
          </AppSwitcherButton>
        )}
      />
      <StyledLink to={ROUTE_PATHS.FAQ.INDEX}>
        <img src={logo} width={24} height={24} alt="logo" />
        <LogoAppName>Human Resource Management</LogoAppName>
      </StyledLink>
    </Container>
  );
}

export default TopNavigationLogo;
