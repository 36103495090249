import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from '@sentry/react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { NotificationsContainer } from 'components/notification';
import { SomethingWentWrongPage } from 'features/errors';
import { ProvideAuth } from 'hooks';
import { AppRoutes, SuspenseWrapper } from 'routes';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GlobalStyle } from 'styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <GlobalStyle />
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
        <QueryClientProvider client={queryClient}>
          <ProvideAuth>
            <BrowserRouter>
              <ErrorBoundary
                fallback={({ resetError }) => <SomethingWentWrongPage onReset={resetError} />}
              >
                <SkeletonTheme baseColor="#F4F5F7" highlightColor="#DFE1E6">
                  <SuspenseWrapper>
                    <AppRoutes />
                  </SuspenseWrapper>
                </SkeletonTheme>
                {process.env.REACT_APP_ENVIRONMENT === 'local' ? (
                  <ReactQueryDevtools initialIsOpen={false} />
                ) : null}
              </ErrorBoundary>
            </BrowserRouter>
          </ProvideAuth>
        </QueryClientProvider>
      </GoogleOAuthProvider>
      <NotificationsContainer />
    </>
  );
}

export default App;
