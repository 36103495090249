interface RouteEntity {
  INDEX: string;
  BY_ID?: string;
}

interface AuthorizationRoutes {
  INDEX: string;
  SIGNIN: RouteEntity;
}

interface AssociatesRoutes {
  INDEX: string;
  BY_ID: string;
  EDIT_PROFILE_BY_ID: {
    INDEX: string;
  };
  APPROVAL_TREE_BY_ID: {
    INDEX: string;
  };
}

interface TimeOffBalanceRoutes {
  INDEX: string;
}

interface PendingRequests {
  INDEX: string;
}

interface TimeOffReportRoutes {
  INDEX: string;
}

interface RolesAndPermissionsRoutes {
  INDEX: string;
}

interface OvertimeUndertimeRoutes {
  INDEX: string;
}

interface SurveysRoutes {
  INDEX: string;
  BY_ID: string;
  COMPLETE: string;
  CREATE_QUESTIONNAIRE: {
    INDEX: string;
    ESAT: {
      INDEX: string;
    };
  };
  NINE_BOX: {
    INDEX: string;
    ASSESS: string;
    RESULT: string;
    EDIT: string;
    ASSESSMENT_STATUS: string;
  };
}

interface CompensationsRoutes {
  INDEX: string;
}

interface RiskRadarRoutes {
  INDEX: string;
}

interface ImportDismissalReasonsRoutes {
  INDEX: string;
}

interface CompensationsRoutes {
  INDEX: string;
}

interface Faq {
  INDEX: string;
}

interface TimeTrackingRoutes {
  INDEX: string;
  TABS: string;
  LOGS: {
    INDEX: string;
    BY_ID: string;
  };
  PROJECT_TASKS: {
    INDEX: string;
  };
  PROJECT_LOGS: {
    INDEX: string;
  };
  PERIOD_MANAGEMENT: {
    INDEX: string;
  };
}

interface PaymentRequiredRoutes {
  INDEX: string;
}

interface RoutePaths {
  ROOT: string;
  AUTHORIZATION: AuthorizationRoutes;
  ASSOCIATES: AssociatesRoutes;
  TIME_OFF_BALANCE: TimeOffBalanceRoutes;
  PENDING_REQUESTS: PendingRequests;
  TIME_OFF_REPORT: TimeOffReportRoutes;
  COMPENSATIONS: CompensationsRoutes;
  ROLES_AND_PERMISSIONS: RolesAndPermissionsRoutes;
  OVERTIME_UNDERTIME: OvertimeUndertimeRoutes;
  SURVEYS: SurveysRoutes;
  IMPORT_DISMISSAL_REASONS: ImportDismissalReasonsRoutes;
  RISK_RADAR: RiskRadarRoutes;
  TIME_TRACKING: TimeTrackingRoutes;
  PAYMENT_REQUIRED: PaymentRequiredRoutes;
  FAQ: Faq;
}

const ROUTE_PATHS: RoutePaths = {
  ROOT: '/',
  AUTHORIZATION: {
    INDEX: '/authorization',
    SIGNIN: {
      INDEX: '/authorization/sign-in',
    },
  },
  ASSOCIATES: {
    INDEX: '/associates',
    BY_ID: '/associates/:id',
    EDIT_PROFILE_BY_ID: {
      INDEX: '/associates/:id/edit-profile',
    },
    APPROVAL_TREE_BY_ID: {
      INDEX: '/associates/:id/approval-tree',
    },
  },
  TIME_OFF_BALANCE: {
    INDEX: '/time-off-balance',
  },
  PENDING_REQUESTS: {
    INDEX: '/pending-requests',
  },
  TIME_OFF_REPORT: {
    INDEX: '/time-off-report',
  },
  COMPENSATIONS: {
    INDEX: '/compensations',
  },
  ROLES_AND_PERMISSIONS: {
    INDEX: '/roles-and-permissions',
  },
  OVERTIME_UNDERTIME: {
    INDEX: '/overtime-undertime',
  },
  SURVEYS: {
    INDEX: '/surveys',
    BY_ID: '/surveys/:id',
    COMPLETE: '/surveys/complete',
    CREATE_QUESTIONNAIRE: {
      INDEX: '/surveys/create-questionnaire',
      ESAT: {
        INDEX: '/surveys/create-questionnaire/esat',
      },
    },
    NINE_BOX: {
      INDEX: '/surveys/nine-box',
      ASSESS: '/surveys/nine-box/assess',
      RESULT: '/surveys/nine-box/result',
      EDIT: '/surveys/nine-box/edit',
      ASSESSMENT_STATUS: '/surveys/nine-box/assessment-status',
    },
  },
  RISK_RADAR: {
    INDEX: '/risk-radar',
  },
  IMPORT_DISMISSAL_REASONS: {
    INDEX: '/import-dismissal-reasons',
  },
  TIME_TRACKING: {
    INDEX: '/time-tracking',
    TABS: '/time-tracking/:tab',
    LOGS: {
      INDEX: '/time-tracking/logs',
      BY_ID: '/time-tracking/logs/:id',
    },
    PROJECT_TASKS: {
      INDEX: '/time-tracking/project-tasks',
    },
    PROJECT_LOGS: {
      INDEX: '/time-tracking/project-logs',
    },
    PERIOD_MANAGEMENT: {
      INDEX: '/time-tracking/period-management',
    },
  },
  PAYMENT_REQUIRED: {
    INDEX: '/payment-required',
  },
  FAQ: {
    INDEX: 'faq',
  },
};

export default ROUTE_PATHS;
