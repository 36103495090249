import { init } from '@sentry/react';
import React from 'react';
import { render } from 'react-dom';
import '@atlaskit/css-reset';

import App from './App';
import reportWebVitals from './reportWebVitals';

function initSentry() {
  if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_ENVIRONMENT,
    });
  }
}

function initializeApp() {
  initSentry();
}

initializeApp();

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
