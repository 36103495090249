import { APPS_PERMISSIONS } from 'api';
import logoCEP from 'assets/images/appIcons/CEP.png';
import logoERP from 'assets/images/appIcons/ERP.png';
import logoHRM from 'assets/images/appIcons/HRM.png';
import logoMC from 'assets/images/appIcons/missionControl.png';

type PermissionsType = {
  erp?: string[];
  ['ya-me']?: string[];
  cep?: string[];
  payments?: string[];
  mc?: string[];
};

const userHasPermissions = (permission: string[] | undefined) => {
  if (!permission) return false;
  return !!permission?.length;
};

export const getApps = (permissions: PermissionsType) => {
  return [
    {
      title: 'Sherp.HRM',
      subTitle: 'Human Resource Management system',
      logo: logoHRM,
      link: process.env.REACT_APP_YAME_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.YA_ME]),
    },
    {
      title: 'Sherp.CEP',
      subTitle: 'Competency Evaluation Platform',
      logo: logoCEP,
      link: process.env.REACT_APP_CEP_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.CEP]),
    },
    {
      title: 'Sherp.ERP',
      subTitle: 'Enterprise Resource Planning',
      logo: logoERP,
      link: process.env.REACT_APP_ERP_FRONT_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.ERP]),
    },
    {
      title: 'Sherp Mission Control',
      subTitle: 'Admin system',
      logo: logoMC,
      link: process.env.REACT_APP_MC_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.MC]),
    },
  ];
};
